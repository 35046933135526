@import 'bootstrap/dist/css/bootstrap.min.css';
@import "~bootstrap-icons/font/bootstrap-icons.css";

body {
  background-color: #f7f7f7;
}

.system-details-card {
  margin: 0 auto;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.25s ease-in;
}

.system-details-card:hover {
  transform: translateY(-1px);
}

a.external_link:hover {
  cursor: pointer
}

