:root {
  --primary-color: #28a745;
}

.spinner-wrapper {
	margin: 1rem auto;
	padding: 1rem;
}

.spinner {
	z-index: 1;
	margin: 0 auto; 
	text-align: center;
	left: 50%;
	margin: 0 0 0 -2rem;
	position: absolute;
}

.spinner > div {
	display: inline-block;
	width: 1.1rem;
	height: 1.1rem;
	border-radius: 100%;
	background-color: var(--primary-color);
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
	background-color: var(--primary-color);
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
	background-color: var(--primary-color);
}

@-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% { -webkit-transform: scale(0); }
	40% { -webkit-transform: scale(1.0); }
}

@keyframes sk-bouncedelay {
	0%, 80%, 100% { 
		-webkit-transform: scale(0);
		-ms-transform: scale(0);
		transform: scale(0);
	} 40% { 
		-webkit-transform: scale(1.0);
		-ms-transform: scale(1.0);
		transform: scale(1.0);
	}
}